.test {
  color: white;
}

.header {
  color: white;
  gap: 0.5rem;
  .logo {
    width: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
    img {
      width: 100%;
    }
  }
}

.contentContainer {
  min-height: calc(100vh - 64px);
  display: grid;
  grid-template-columns: auto 25rem auto;
}

.main {
  padding-top: 5rem;
  .card {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;

    h3 {
      margin: 0.25rem 0;
    }
  }
}
