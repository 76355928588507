.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .listHeader {
    display: flex;
    flex-direction: row;

    .addNew {
      margin-left: 2rem;
    }
  }
  .itemMoving {
    background-color: #d9d9d9;
  }
  .tools {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
}

.overlayModal {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  .overlaylink {
    padding: 0.5rem;
    background-color: #f5f5f5;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
  }

  .copy {
    margin-left: auto;
    margin-right: auto;
  }
}
