.test {
  color: white;
}

.contentContainer {
  min-height: calc(100vh - 64px);
  display: grid;
  grid-template-columns: auto 25rem auto;
}

.main {
  padding-top: 5rem;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  & > div {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }
}
