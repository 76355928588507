.container {
}
.header {
  gap: 0.5rem;
  .logo {
    width: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
    img {
      width: 100%;
    }
  }
}

.contentContainer {
  min-height: calc(100vh - 64px - 64px);
  display: grid;
  grid-template-columns: auto 70vw auto;
  padding: 1rem;
}

.main {
}

.profile {
  margin-left: auto;
  font-weight: bold;
  span {
    margin-left: 0.25rem;
  }
}
