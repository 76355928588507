.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .list {
    width: 500px;
    overflow: hidden;
    ol {
      display: flex;
      flex-direction: row;
      list-style: none;
      gap: 0.25rem;
      margin: 3px;
      width: max-content;
      animation: scroll 20s linear infinite;

      @keyframes scroll {
        0% {
          transform: translateX(500px);
        }
        100% {
          transform: translateX(-100%);
        }
      }
    }
  }
}
