.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;

  .bg {
    height: 30rem;
    position: absolute;
    bottom: 7rem;
    filter: opacity(0.7);
  }

  h1 {
    font-weight: 400;
    font-size: 2.5rem;
  }

  p {
    margin: 0;
    margin-bottom: 0.5rem;
  }

  .cta {
    margin-top: 1rem;
  }

  .online {
    color: #1c89cc;
  }
}
