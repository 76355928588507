.link {
  padding: 0.25rem;
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}
.mdcontent {
  img {
    max-height: 15rem;
  }
}
